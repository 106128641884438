<template>
  <div class="main-page">
    <el-card>
      <div slot="header" class="clearfix">
      <span>新上线问答数：{{
          activeName === 'weelv' ? weelvXmlList.question_count : younailXmlList.question_count
        }}，新上线问答数满{{ weelvXmlList.generate_xml_question_count}}自动生成xml文件</span>
        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
      </div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="微旅" name="weelv">
          <el-table class="mg-t20" :highlight-current-row="true" :stripe="true" :data="weelvXmlList.data"
                    align="left">
            <el-table-column prop="xml_path" label="xml路径"></el-table-column>
            <el-table-column prop="items_count" label="包含问答数量">
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="weelvXmlList.total"
              :page-size="pageSize"
              :current-page="weelvPage"
              @current-change="weelvPageChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="花袖" name="younail">
          <el-table class="mg-t20" :highlight-current-row="true" :stripe="true" :data="younailXmlList.data"
                    align="left">
            <el-table-column prop="xml_path" label="xml路径"></el-table-column>
            <el-table-column prop="items_count" label="包含问答数量">
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="younailXmlList.total"
              :page-size="pageSize"
              :current-page="younailPage"
              @current-change="younailPageChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {SOUGOU_XML_GET_WEELV_LIST, SOUGOU_XML_GET_YOUNAIL_LIST,} from '@/store/mutations'

export default {
  computed: {
    ...mapState({
      weelvXmlList: state => state.sougouIndex.weelvXmlList,
      younailXmlList: state => state.sougouIndex.younailXmlList,
    })
  },
  mounted() {
    this.getWeelvXmlList();
  },
  watch: {},
  data() {
    return {
      activeName: 'weelv',
      weelvPage: 1,
      younailPage: 1,
      pageSize: 20,
    }
  },
  methods: {
    ...mapActions({
      SOUGOU_XML_GET_WEELV_LIST,
      SOUGOU_XML_GET_YOUNAIL_LIST,
    }),

    handleClick() {
      if (this.activeName === 'weelv') {
        this.getWeelvXmlList();
      } else if (this.activeName === 'younail') {
        this.getYounailXmlList();
      }
    },

    getWeelvXmlList() {
      this[SOUGOU_XML_GET_WEELV_LIST]({
        type: SOUGOU_XML_GET_WEELV_LIST,
        params: {
          page: this.weelvPage,
          pageSize: this.pageSize,
        }
      });
    },

    getYounailXmlList() {
      this[SOUGOU_XML_GET_YOUNAIL_LIST]({
        type: SOUGOU_XML_GET_YOUNAIL_LIST,
        params: {
          page: this.younailPage,
          pageSize: this.pageSize,
        }
      });
    },
    searchWeelvUrlList() {
      this.weelvPage = 1;
      this.getWeelvXmlList();
    },
    weelvPageChange(page) {
      this.weelvPage = page
      this.getWeelvXmlList();
    },
    searchYounailUrlList() {
      this.younailPage = 1;
      this.getYounailXmlList();
    },
    younailPageChange(page) {
      this.younailPage = page
      this.getYounailXmlList();
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
